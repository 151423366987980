import React, { useEffect, useMemo, useState } from "react";
import { Navigate } from "react-router-dom";
import { signInUser } from "../redux/userLogginSlice";
import { auth, EmailAuthProvider, sendEmailVerification, User } from '../utils/firebase';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getRegistrationStatus, postNewAdminUser } from "../utils/restApi";
import { ApiError, errorCodeToSnackbarMessage } from "../utils/error";
import { PROJECT_PATH } from "./routes";
import { openSnackbar } from "../redux/snackbarSlice";
import { NotifType } from "../components/customSnackbarComponent/CustomSnackbarComponent";

export interface RouteProtectorProps {
    readonly requiredLogin?: boolean | undefined;
    readonly children: JSX.Element;
}

export const RouteProtector = (props: RouteProtectorProps) => {
    const {requiredLogin, children} = props;
    const [firebaseUid, setFirebaseUid] = useState<string>('');
    const dispatcher = useAppDispatch();
    const isLoggedIn = useAppSelector((state)=> state.userLoginReducer.isLoggedIn);

    const uiConfig = useMemo(()=>{
        // FirebaseUI configuration
        return {
                signInFlow: 'popup',
                signInOptions: [EmailAuthProvider.PROVIDER_ID],
                callbacks: {
                signInSuccessWithAuthResult: (authResult: any) => {
                    //authResult has type any when I checked index.d.ts file of firebaseui
                    const user: User = authResult.user;
                    const isNewUser: boolean = authResult.additionalUserInfo.isNewUser;
                    if(isNewUser){
                        sendEmailVerification(user);
                    };

                    return false;
                },
                signInFailure: (error) => {
                    dispatcher(openSnackbar({
                        isOpen: true,
                        type: NotifType.ERROR,
                        message: 'ログインに失敗しました',
                        duration: 2000,
                    }));
                },
            },
        };
    }, [dispatcher]);


    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged( async (user) => {         
            if(user){
                const idToken = await user.getIdToken();
                const isEmailVerified = user.emailVerified;

                if(!isEmailVerified){
                    dispatcher(openSnackbar({
                        isOpen: true,
                        type: NotifType.ERROR,
                        message: 'メールアドレス確認が完了していません',
                        duration: 2000,
                    }));

                    // signout user from the firebase                    
                    auth.signOut();

                    // Update the state to force re-render the component.
                    setFirebaseUid(user.uid + Date.now().toString()); 
                } else {
                     // This has to be sequential because idToken is required to in the 'Authorization' header
                    const registrationStatus = await getRegistrationStatus(idToken);
    
                    if(registrationStatus instanceof ApiError){
                        // If account is not registered in backend DB, then it needs to register firebase idToken
                        if(registrationStatus.code === 'account_not_found'){
                            // post new user when account was not found
                            const registrationResponse = await postNewAdminUser(idToken);
                            if(registrationResponse instanceof ApiError){
                                dispatcher(openSnackbar({
                                    isOpen: true,
                                    type: NotifType.ERROR,
                                    message: 'データベースに管理ユーザー登録失敗', // failed to register user into DB
                                    duration: 10000,
                                }));

                                // Signout user from the firebase
                                auth.signOut();

                                // Update the state to force re-render the component.
                                // This creates new account in the firebase, but that account is not registered in database.
                                setFirebaseUid(user.uid + Date.now().toString()); 
                            } else {
                                dispatcher(signInUser({
                                    isLoggedIn: true,
                                    displayName: user.displayName,
                                    email: user.email,     
                                }));
                            }
                        } else {
                            dispatcher(openSnackbar({
                                isOpen: true,
                                type: NotifType.ERROR,
                                message: errorCodeToSnackbarMessage[registrationStatus.code] ?? 'ログイン失敗',
                                duration: 2000,
                            }));
                        }
                    } else {
                        // User found in the database, so successful login
                        dispatcher(signInUser({
                            isLoggedIn: true,
                            displayName: user.displayName,
                            email: user.email,          
                        }));
                    }
                }
            };
        });
        return () => unregisterAuthObserver();
    }, [dispatcher]);

    
    if(requiredLogin === true && !isLoggedIn){
        return (
            <StyledFirebaseAuth key={firebaseUid} uiConfig={uiConfig} firebaseAuth={auth} />
        )
    }

    if (requiredLogin === false && isLoggedIn) {
        return (
            <Navigate to={PROJECT_PATH.HOME} />
        );
    }

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
};