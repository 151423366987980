import { createSlice } from "@reduxjs/toolkit";
import { NotifType } from "../components/customSnackbarComponent/CustomSnackbarComponent";
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ToggleSnackbarState {
    isOpen: boolean
    type: NotifType
    message: string
    duration: number
}
 
const initialState: ToggleSnackbarState = {
    isOpen: false,
    type: NotifType.SUCCESS, //Setting defualt to value to be Success
    message: '',
    duration: 0,
};

/*
* This slice controls open and close of the snackbar globally.
* So, it can be reused anywhere in the appliaction. 
*/
export const snackbarSlice = createSlice({
    name:'snackbarSlice',
    initialState,
    reducers: {
        // Create reducer to open the snackbar and send necessary information as new state
        openSnackbar:(state, action:PayloadAction<ToggleSnackbarState>) => {
            const {isOpen, type, message, duration} = action.payload;
            state.isOpen = isOpen;
            state.type = type;
            state.message = message;
            state.duration = duration;
        },
        closeSnackbar:(state) => {
            state.isOpen = false;
        },
    },
});

export const {openSnackbar, closeSnackbar} = snackbarSlice.actions;
export default snackbarSlice.reducer;