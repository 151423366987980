import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'

export interface UserLogInState {
    readonly isLoggedIn?: boolean | undefined
    readonly displayName?: string | null
    readonly email?: string | null
}
 
const initialState: UserLogInState = {
    isLoggedIn: false,
    displayName: undefined,
    email: undefined,
};

export const userLoginSlice = createSlice({
    name:'userLoginSlice',
    initialState,
    reducers: {
        signInUser:(state, action:PayloadAction<UserLogInState>) => {
            const { isLoggedIn, displayName, email} = action.payload;
            state.isLoggedIn = isLoggedIn;
            state.displayName = displayName;
            state.email = email;
        },
        signOutUser:(state) => {
            state.isLoggedIn = false;
            state.displayName = null;
            state.email = null;
        },
    },
});

export const {signInUser, signOutUser} = userLoginSlice.actions;
export default userLoginSlice.reducer;