import { Box, createTheme } from "@mui/material"

export const NoDataFoundPage = () => {
    const theme = createTheme();

    return (
        <Box
            sx={{
                marginTop: theme.spacing(3),
                textAlign: 'center',
            }}
        >
            <Box 
                className='pageLabelContainer'
                sx={{
                    fontSize: 32,
                }}
            >   
                データが見つかりませんでした
            </Box>
        </Box>
    )
}