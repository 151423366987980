import { DrawerComponent, DrawerMenuFields } from "../components/DrawerComponent";
import { useMemo } from "react";


export const DrawerContainer = () => {

    /*
    * Return empty array for drawer, and it can be reused when it needs to expand
    */
    const menuItems = useMemo<DrawerMenuFields[]>(() => {
        return [];
    }, [])

    return (
        <DrawerComponent 
            appName={"チケットDX管理者サイト"}
            drawerMenuItems={menuItems}                
        />
    )
}