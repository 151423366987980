import { useCallback } from 'react';
import { Box, Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

export enum NotifType {
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
}

export interface NotificationBarProp{
    readonly isOpen: boolean
    readonly type: NotifType
    readonly message: string 
    readonly duration: number
    readonly handleClose: () => void
}

export const CustomSnackbarComponent = (props: NotificationBarProp) => {
    
    const handleClose = useCallback(() => {
            props.handleClose();
    },[props])

    return (
        <Box>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={props.isOpen}
                autoHideDuration={props.duration}
                onClose={handleClose}
            >
                <Alert className='alert'
                    onClose={handleClose} 
                    severity={props.type}
                >
                    {props.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}