import { initializeApp, getApps } from 'firebase/app';
import { User, getAuth, EmailAuthProvider, sendEmailVerification } from 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID, 
};

if(getApps.length === 0){
    initializeApp(firebaseConfig);
}
const auth = getAuth();

export { auth, EmailAuthProvider, sendEmailVerification };    
export type { User };

