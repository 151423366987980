import { Routes, Route } from "react-router-dom";
import { Layout } from "../containers/Layout";
import { NotFoundPage } from "../pages/NotFoundPage";
import { RouteProtector } from "./routeProtector";
import { CSVDownloadContainer } from "../containers/CSVDownloadContainer";


export const PROJECT_PATH = Object.freeze({
    HOME : '/',
});

export const AppRoutes: React.FC = () => {  

    return (
        <Routes>
            <Route 
                path={PROJECT_PATH.HOME}
                element={
                    <RouteProtector
                        requiredLogin
                        children={<Layout><CSVDownloadContainer/></Layout>}
                    />                    
                }
            />
            <Route 
                path='*'
                element={
                    <RouteProtector
                        requiredLogin
                        children={<Layout><NotFoundPage/></Layout>}
                    />         
                }
            />
        </Routes>
    )
}