import { Box, Button, Menu, MenuItem } from "@mui/material"
import { useState } from "react";
import { auth } from '../utils/firebase';
import { signOutUser } from "../redux/userLogginSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import Logout from "@mui/icons-material/Logout";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const LogoutMenuComponent = () => {    
    const {displayName, email} = useAppSelector((state) => state.userLoginReducer);
    const dispatcher = useAppDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);  
    };
    const handleClick = () => {
        auth.signOut();
        dispatcher(signOutUser());
    }

    return (
        <Box>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleOpen}
                sx={{
                    color: '#FFFFFF',
                }}
            >   
                {displayName ?? email} <ArrowDropDownIcon/>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleClick}>
                    <Logout/> ログアウト
                </MenuItem>
            </Menu>
        </Box>
    )
}