import axios, { isAxiosError } from 'axios';
import { GameListWithEntries } from './common';
import { ApiError } from './error';

export interface GetGamesWithEntriesParams {
    readonly onlyHome?: boolean | undefined // onlyHome means return only home games of given team
}

export interface RegistrationStatusResponseData {
    readonly createdAt: string
    readonly id: number
    readonly uid: string
}

export interface ErrorResponse {
    readonly code: string;

    // details only exist for 500 level error.
    // Key is exception name and value contains a message associated with the exception
    readonly details?: object | undefined;
    readonly message: string;
}

const isErrorResponse = (obj: unknown): obj is ErrorResponse => {
    const errorResponse = obj as ErrorResponse;
    return (
        errorResponse &&
        typeof errorResponse.code === 'string' &&
        (errorResponse.details === undefined ||
            (!Array.isArray(errorResponse.details) &&
                typeof errorResponse.details === 'object')) &&
        typeof errorResponse.message === 'string'
    );
};

const handleErrorResponse = (error: unknown): ApiError => {
    if (
        isAxiosError(error) &&
        error?.response &&
        isErrorResponse(error.response.data)
    ) {
        const { code, details, message } = error.response.data;
        return new ApiError(code, message, details);
    } else if (error instanceof Error) {
        return new ApiError(error.name, error.message);
    }
    return new ApiError('UNEXPECTED_ERROR', 'Unexpected error occurred');
};

const BASE_URL = process.env.REACT_APP_BASE_URL;

/*
 * This handler gets current registration status of a given user. 
 * If user is registered in the database, the it return 200 level. Else, returns 400 level.
 */
export const getRegistrationStatus = async (idToken: string): Promise<RegistrationStatusResponseData | ApiError> => {
    try {
        const response = await axios.get<RegistrationStatusResponseData>(
            `${BASE_URL}/admin/me`,
            {
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${idToken}` 
                },
            }
        );
        return response.data;
    } catch (error) {
        return handleErrorResponse(error);
    }
};

/*
 * This handler registers idToken of a user who is registered in the firebase, but not in the backend API database.
 */
export const postNewAdminUser = async (idToken: string): Promise<ApiError> => {
    try {
        const response = await axios.post(
            `${BASE_URL}/admin/me`,
            {
                // empty data
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${idToken}` 
                },
            }
        );
        return response.data;
    } catch (error) {
        return handleErrorResponse(error);
    }
}

/*
 * This handler gets list of with supporter entries.
 */
export const getGamesWithEntries = async (idToken: string, teamCode: string, params?:GetGamesWithEntriesParams ): Promise<GameListWithEntries | ApiError> => {
    try {
        const response = await axios.get<GameListWithEntries>(
            `${BASE_URL}/admin/teams/${teamCode}/entries/games`,
            {
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${idToken}` 
                },
                params,
            }
        );
        return response.data;
    } catch (error) {
        return handleErrorResponse(error);
    }
};

/*
* This handler gets csv file from backend and downloads it using the browser.
*/
export const getCSVFile = async (idToken: string, teamCode: string, eventUuid: string): Promise<Blob | ApiError>  => {
    try {
            const response = await axios.get(
                `${BASE_URL}/admin/teams/${teamCode}/entries/games/${eventUuid}/csv`,
                {
                    responseType: 'blob',
                    headers: {                    
                        'Authorization': `Bearer ${idToken}` 
                    },
                }
            );

            return response.data;
        } catch(error) {
            return handleErrorResponse(error);
        }
}