import { useCallback, useEffect, useState } from "react";
import { NotifType } from "../components/customSnackbarComponent/CustomSnackbarComponent";
import { TableComponent, TableHeaderFields } from "../components/TableComponent";
import { useAppDispatch } from "../redux/hooks";
import { openSnackbar } from "../redux/snackbarSlice";
import { GameListWithEntries } from "../utils/common";
import { ApiError } from "../utils/error";
import { auth } from "../utils/firebase";
import { getCSVFile, getGamesWithEntries, GetGamesWithEntriesParams } from "../utils/restApi";

const TEAM_CODE = 'mito';

const tableHeaders: TableHeaderFields[] = [
    {
        headerValue: 'eventStartAt',
        displayValue: '試合日',
    },
    {
        headerValue: 'title',
        displayValue: '試合名',
    },
    {
        headerValue: 'team1',
        displayValue: 'ホーム',
    },
    {
        headerValue: 'team2',
        displayValue: 'アウェイ',
    },
]

export const CSVDownloadContainer = () => {
    const [gameList, setGameList] = useState<GameListWithEntries | undefined>(undefined);
    const dispatcher = useAppDispatch();

    useEffect(() => {
        async function fetchData() {
            const user = auth.currentUser;
            if(user){
                const queryParams: GetGamesWithEntriesParams = {
                    onlyHome: true,
                };
                const idToken = await user.getIdToken();
                const response = await getGamesWithEntries(idToken, TEAM_CODE, queryParams);
                if(response instanceof ApiError){
                    dispatcher(openSnackbar({
                        isOpen: true,
                        type: NotifType.ERROR,
                        message: `試合情報の受信に失敗`,
                        duration: 2000,
                    }));
                } else {
                    setGameList(response);
                }
            }
          }
        fetchData();
    }, [dispatcher]);

    const handleDownload = useCallback(async(eventUuid: string, title: string) => {
        const user = auth.currentUser;
        if(user){
            const idToken = await user.getIdToken();
            const response = await getCSVFile(idToken, TEAM_CODE, eventUuid);
            if(response instanceof ApiError){
                dispatcher(openSnackbar({
                    isOpen: true,
                    type: NotifType.ERROR,
                    message: 'ダウンロード失敗',
                    duration: 2000,
                }));
            } else {
                const href = URL.createObjectURL(response);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${title}.csv`);
                link.click();
                URL.revokeObjectURL(href);

                // Download was successful
                dispatcher(openSnackbar({
                    isOpen: true,
                    type: NotifType.SUCCESS,
                    message:'ダウンロード成功',
                    duration: 2000,
                }));
            }
        }    
    },[dispatcher]) 

    return (
        <TableComponent
            tableHeaders={tableHeaders}
            dataList={gameList?.games}
            onDownload={handleDownload}
        />
    );
}