import { useCallback } from "react";
import { CustomSnackbarComponent } from "../../components/customSnackbarComponent/CustomSnackbarComponent";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { closeSnackbar } from "../../redux/snackbarSlice";


/*
* This is just a container to pass necessary props to the snackbar component.
* Only isOpen, type, message, and duration are supplied from the global store.
*/
export const CustomSnackbarContainer = () => {
    const {isOpen, type, message, duration} = useAppSelector((state: RootState) => state.snackbarReducer)
    const dispatcher = useAppDispatch();

    const handleClose = useCallback(() => {
        dispatcher(closeSnackbar())
    },[dispatcher])

    return (
        <CustomSnackbarComponent
            isOpen={isOpen}
            type={type}
            message={message}
            duration={duration}
            handleClose={handleClose}
        />
    );
}