import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableFooter, CircularProgress, Box, createTheme, IconButton } from "@mui/material"
import Paper from '@mui/material/Paper';
import DownloadIcon from '@mui/icons-material/Download';
import { GameInfo } from "../utils/common";
import { NoDataFoundPage } from "../pages/NoDataFoundPage";
import React, { useCallback, useState, useMemo } from "react";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

export interface TableHeaderFields {
    readonly headerValue: string
    readonly displayValue: string 
}

export interface TableComponentProps {
    readonly tableTitle?: string | undefined
    readonly tableTitleIcon?: JSX.Element | undefined
    readonly tableHeaders: readonly TableHeaderFields[]
    readonly dataList?: readonly GameInfo[] | undefined
    readonly onDownload: (eventUuid: string, title: string) => void
}

interface TablePaginationActionProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
};

const TablePaginationActions = (props: TablePaginationActionProps) => {
    const theme = createTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

const INITIAL_ROWS_PER_PAGE = 5;

export const TableComponent = (props: TableComponentProps) => {
    const {dataList, tableHeaders, onDownload} = props;
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(INITIAL_ROWS_PER_PAGE); // Setting initial rows per page to be 5

    const handleChangePage = useCallback((event: React.MouseEvent | null, newPage: number) => {
        setPage(newPage);
    }, []);

    const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    },[]);

    /*
    * Returns setting for tablePagination. When dataList is undefined, it returns default setting.
    */
    const tableRowPerPageSetting = useMemo(() => {   
        const tableRowPerPageSizes: number[] = [5, 10, 25, 50];

        // This is to make show all data to show up in the dropdown menu of the pagination.
        // Since bigger than any other RowPerPageSize, it guarantees all data shows up on the table.
        const ALL_ROWS_VALUE = 999999; 
        
        if(dataList){
            const rowsPerPageSetting = tableRowPerPageSizes.map((size) => {
                return {
                    label: `${size}件`,
                    value: size,
                };
            });
            rowsPerPageSetting.push({ label: '全て', value: ALL_ROWS_VALUE });
            return rowsPerPageSetting;
        }

        return [
            { label: '全て', value: 0 },
        ]
    }, [dataList]);
 
    if(dataList && dataList.length > 0){
        
        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead
                        sx={{
                            backgroundColor: '#F2F2F2', 
                        }}
                    >
                        <TableRow>
                            {tableHeaders.length > 0 && tableHeaders.map((header) => {
                                return (
                                    <TableCell 
                                        key={header.headerValue}
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {header.displayValue}
                                    </TableCell>
                                );
                            })}
                        
                            {tableHeaders.length > 0 && <TableCell/>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {dataList && dataList.length > 0 && 
                                dataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((game) => {
                                return (
                                    <TableRow key={game.eventUuid}>
                                        {tableHeaders.filter(header => game[header.headerValue])
                                            .map((header) => {
                                                const headerValue = header.headerValue;

                                                if(headerValue === 'eventStartAt'){
                                                    return (
                                                        <TableCell key={headerValue}>
                                                            {game[headerValue].slice(0, game[headerValue].indexOf('T'))}
                                                        </TableCell>
                                                    )
                                                }
                                                
                                                if(headerValue === 'team1' || headerValue === 'team2'){
                                                    return (
                                                        <TableCell key={headerValue}>
                                                            {game[headerValue].name}
                                                        </TableCell>
                                                    )
                                                }
    
                                                return (
                                                    <TableCell key={headerValue}>{game[headerValue]}</TableCell>
                                                );
                                        })}
    
                                        {tableHeaders.length > 0 &&
                                            <TableCell
                                                sx={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                                <DownloadIcon 
                                                    sx={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {onDownload(game.eventUuid, game.title)}}                                                
                                                />
                                            </TableCell>
                                        }
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                labelRowsPerPage='表示件数:'
                                rowsPerPageOptions={tableRowPerPageSetting}

                                colSpan={5}
                                count={dataList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        )
    };

    // Data came back from backend, but that data is empty then show NoDataFoundPage
    if(dataList && dataList.length === 0){
        return (<NoDataFoundPage/>);
    };

    // When dataList is undefined, which means data hasn't cameback from backend, show CircularProgress
    return (<CircularProgress/>);
};
