export const errorCodeToSnackbarMessage = Object.freeze({
    'account_not_found':'ユーザーが登録されていません',
    'invalid_email':'無効なメールアドレス',
    'invalid_auth_token':'認証トークンが無効です',
    'validation_error':'無効なデータです',
    'db_operation_failed':'サーバーエラー',
});

export class ApiError extends Error {
    public code: string;
    public details?: object | undefined
    public message: string;

    constructor(code: string, message: string, details?: object){
        super();
        this.code = code;
        this.details = details;
        this.message = message;
    }
}